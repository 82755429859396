/**
 * @Date:   2019-10-15T10:57:10+01:00
 * @Last modified time: 2020-02-13T15:14:21+00:00
 */

import React from 'react';
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class Home extends React.Component {
  render(){
    return(
      <div className = "container">
        <div className="center middle">
          <img  className="imageBorder topSpace" src="../../../images/logo.png"/>

          <h2 className="topSpace white"><b>AsFan Website coming soon!</b></h2>

        </div>
        <div className="center middle white topSpace">

              <h4><i>"We will speak on your behalf, we will fight for you,
              we will advocate for you until the day you can do it yourself"</i></h4>

              <h5>Our mission is to improve access to appropriate services, education, training, housing,
              employment and autism informed support services for all individuals on the autism spectrum and
              their families.</h5>
              <hr/>
              <h5>Work in a collaborative way with other organisations, playmakers, educators, service providers,
              employers and the community to inform, educate, raise awareness on how to engage with, provide support,
              enhance the inclusion of individuals and their families in all aspects of Irish society. We will do this
              through providing advocacy, education, training, mentoring or coaching. </h5>

        </div>
        <br/>
        <h3 className="center middle white topSpace">In the meantime, if you would like to get in touch:</h3>
        <br/>
        <div className="topSpace">
          <Row>
            <Col className="middle_icon">
              <a href="https://www.facebook.com/asfanirl" className="link" style={{ textDecoration: 'none' }} >
                <img  className="middle_icon grow" src="../../../images/facebook.png"/>
                <h4 className="white topSpace link">Look for us on Facebook!</h4>
              </a>
            </Col>


            <Col className="middle_icon">
              <a href="mailto:asfanetwork@gmail.com" className="link"style={{ textDecoration: 'none' }} >
                <img  className="middle_icon grow" src="../../../images/email.png"/>
                <h4 className="white topSpace">Email us!</h4>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}


export default Home;
