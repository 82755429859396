/**
 * @Date:   2019-10-21T10:56:08+01:00
 * @Last modified time: 2020-02-12T17:42:34+00:00
 */
import React, {Component} from 'react';
// import Characters from './components/characters';
// import Episodes from './components/episodes';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/home';



// Put any other imports below so that CSS from your
// components takes precedence over default styles.

class App extends Component {



  render() {



    return (
    <BrowserRouter>
        <Switch>
          <Route exact path = "/" component = {Home}/>

        </Switch>
      </BrowserRouter>
    );
  }


  }

  export default App;
